import './i18n';
import * as React from "react"
import { Link } from "gatsby"
import { useEffect } from "react";
import i18n from "./i18n";
import { useTranslation } from "react-i18next";

// styles
const pageStyles = {
  color: "#232129",
  padding: "96px",
  fontFamily: "-apple-system, Roboto, sans-serif, serif",
}
const headingStyles = {
  marginTop: 0,
  marginBottom: 64,
  maxWidth: 320,
}

const paragraphStyles = {
  marginBottom: 48,
}
const codeStyles = {
  color: "#8A6534",
  padding: 4,
  backgroundColor: "#FFF4DB",
  fontSize: "1.25rem",
  borderRadius: 4,
}

// markup
const NotFoundPage = ({ pageContext }) => {
  const { locale } = pageContext;
  const { t } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(locale);
  }, []);

  return (
    <main style={pageStyles}>
      <title>Not found</title>
      <h1 style={headingStyles}>{t('404.page-not-found')}</h1>
      <p style={paragraphStyles}>
        Sorry{" "}
        <span role="img" aria-label="Pensive emoji">
          😔
        </span>{" "}
        {t('404.sorry')}
        <br />
        {process.env.NODE_ENV === "development" ? (
          <>
            <br />
            Try creating a page in <code style={codeStyles}>src/pages/</code>.
            <br />
          </>
        ) : null}
        <br />
        <Link to="/">{t('404.home')}</Link>.
      </p>
    </main>
  )
}

export default NotFoundPage
